<template>
	<ContentWrapper>
		<b-container fluid="lg">
			<b-row>
				<b-col>
					Locations
					<br />add-empty-option
				</b-col>
				<b-col>
					<p-select dataType="locations"
															add-empty-option v-model="locations" />
				</b-col>
				<b-col>Selected Location: {{ locations }}</b-col>
			</b-row>
			<p></p>
			<hr/>
			<b-row>
				<b-col>
					<p-select label="Stored drop down options" :options="dataTypes" v-model="selectedDataType" />
				</b-col>
				<b-col>
					<p-select v-if="selectedDataType" item-text="value" :key="selectedDataType" :label="selectedDataType" :data-type="selectedDataType" v-model="storeSelection" />
					<div v-else>
						Select a drop down type
					</div>
				</b-col>
				<b-col ><div v-if="storeSelection && selectedDataType">{{ storeSelection }} selected from {{selectedDataType}}</div>
				</b-col>
			</b-row>
			<hr/>
			<p></p>
			<b-row>
				<b-col>Countries</b-col>
				<b-col>
					<p-select dataType="countries" v-model="countries" />
				</b-col>
				<b-col>Selected Countries: {{ countries }}</b-col>
			</b-row>
			<b-row>
				<b-col>States</b-col>
				<b-col>
					<p-select dataType="states"
															v-model="states"
															:filter="countries"
															add-empty-option
															@filter="(event) => countries = event || countries" />
				</b-col>
				<b-col>Selected State: {{ states }}</b-col>
			</b-row>
			<p></p>
			
			<b-row>
				<b-col cols="5">
					<b-row class="pt-3">
						<b-col>ProductMultiSelectQuickSearch<br />Add Only<br />enable-row-selection="false"</b-col>
						<b-col>
							<ProductMultiSelectQuickSearch name="ProductMultiSelectQuickSearch"
																																			:enable-row-selection="false"
																																			@selections="addSelections" />
						</b-col>
					</b-row>
					<b-row class="pt-3">
						<b-col>ProductMultiSelectQuickSearch<br />Add and Remove</b-col>
						<b-col>
							<ProductMultiSelectQuickSearch name="ProductMultiSelectQuickSearchAddAndRemove"
																																			label="Select Items"
																																			:previous-selections="selectedItems"
																																			@selections="setSelections" />
						</b-col>
					</b-row>
				</b-col>

				<b-col cols="7" class="max-height">Items ({{ selectedItems.length }}): <div v-for="item in selectedItems" :key="item.sku">{{ JSON.stringify(item) }}</div></b-col>
			</b-row>
		</b-container>
	</ContentWrapper>
</template>

<script>
	import ProductMultiSelectQuickSearch from "@/components/ProductMultiSelectQuickSearch.vue";

	import unionBy from "lodash/unionBy";

	export default {
		name: "Selects",
		data() {
			return {
				locations: null,
				countries: null,
				states: null,
				selectedItems: [],
				storeSelection: null,
				selectedDataType:null,
				dataTypes:['accounts',
							'bindings',
							'campuses',
							'countries',
							'courseDepartments',
							'courseLevels',
							'courseRequestAdoptionStatus',
							'courseRequestBookStatuses',
							'courses',
							'dcc',
							'descriptiveColors',
							'gmSizes',
							'instructors',
							'locations',
							'packageTypes',
							'poStatus',
							'pos',
							'priceTagTypes',
							'productTypes',
							'sequenceTypes',
							'shippingLevels',
							'shippingProviders',
							'states',
							'stockAdjustmentReasons',
							'suppliers',
							'taxItems',
							'terms',
							'textStatus',
							'workStatus']
			};
		},
		created() {
		},
		computed: {
		},
		methods: {
			addSelection(item) {
				if (!this.selectedItems.some((x) => x.sku === item.sku)) {
					this.selectedItems.push(item);
				}
			},
			addSelections(items) {
				this.selectedItems = unionBy(items, this.selectedItems, "sku");
			},
			setSelections(items) {
				this.selectedItems = items;
			},

			showModal() {
				this.$refs.addOptionModal.show();
			}
		},
		mounted: function () { },
		components: {
			ProductMultiSelectQuickSearch,
		},
	};
</script>


<style scoped>
>>> .max-height {
  max-height: 350px;
  overflow: auto;
}
</style>
