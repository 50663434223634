

<template>
    <div>
        <p-modal
            :icon-plus="iconPlus"
            :button-label="label"
            :name="name"
            :label="title || label"
            :disable-button="disableAddButton"
            size="xl"
            @show="initialize"
            @ok="submitSelections"
            @shown="focusSearchInput"
            @hidden='$emit("hidden",$event)'
            :hide-footer="hideButtons"
            :ok-only="okOnly"
            :ok-title="okTitle"
            :button-busy="isBusy"
        >

            <template v-slot:default>
                <slot name="abovetable"></slot>
                <slot name="additionalFilters" :filters="filters"></slot>
                <template v-if='showContent'>
                    <p-table v-if="items"
                             v-bind="$attrs"
                             :filters="computedFilters"
                             :fields="searchResultFields"
                             :single-select="singleSelect"
                             :single-select-button-label="singleSelectButtonLabel"
                             :items="items"
                             :disable-row-check="disableRowCheck"
                             :enable-dual-selection="enableDualSelection"
                             :sort-by="defaultSort"
                             @submit-selection="submitSelections"
                             @per-page-change="perPageUpdate"
                             v-model="selections">
                        <!-- pass throught the p-table above-table slot -->
                        <slot name="above-table" slot="above-table" />
                    </p-table>
                    <div v-else>
                        <b-row>
                            <b-col>
                                <b-input-group class='px-3'>
                                    <!-- Wanted to remove these search inputs and use the standard p-search-tree
                                    search filters, but I couldn't get the computedFilters to be reactive when I did that -->
                                    <b-form-input size="md"
                                                  ref="searchInput"
                                                  name="automatic"
                                                  type="search"
                                                  hide-label
                                                  v-model="filters.automatic"
                                                  :placeholder="searchMessageComputed"
                                                  :debounce-wait="300"
                                                  @keydown.enter.prevent="" />
                                    <p-select class='ml-2' v-model="filters.searchBy" :options='filterOptionsComputed' />
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3 ml-2" cols-xl="4" cols-lg="4" cols-md="4" cols-sm="2">
                            <b-col class="d-flex pt-2" v-if="showBuyersGuide">
                                <p-checkbox v-model="includeBuyersGuideTitles"
                                            label="Include Buyers Guide"></p-checkbox>
                            </b-col>
                            <b-col v-if="courseRequestFilters">
                                <p-multiselect :options="selectListOptions.terms"
                                               placeholder="All Terms"
                                               v-model="filters.selectedTerms"
                                               @input="() => {filters.courseDepartmentTermIds = filters.selectedTerms.map(x => x.value)}" />
                            </b-col>
                            <b-col v-if="courseRequestFilters">
                                <p-multiselect :options="filterDepartmentOptionsComputed"
                                               placeholder="All Departments"
                                               v-model="filters.selectedCourseDepartments"
                                               @input="() => {filters.courseDepartmentIds = filters.selectedCourseDepartments.map(x => x.value)}" />
                            </b-col>
                            <b-col v-if="courseRequestFilters">
                                <p-multiselect :options="filterCourseOptionsComputed"
                                               placeholder="All Courses"
                                               v-model="filters.selectedCourses"
                                               @input="() => {filters.courseIds = filters.selectedCourses.map(x => x.value)}" />
                            </b-col>
                        </b-row>
                        <b-row class='pt-2' v-if="showWholesaleOption">
                            <b-col>
                                <p-checkbox v-model='withWholesaleValue'
                                            label="Only show products with wholesale value"></p-checkbox>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3 ml-2" v-if="includeTerms">
                            <b-col cols="12" md="auto">
                                <p-checkbox v-model="associateWithTerm"
                                            label="Show with term"></p-checkbox>
                            </b-col>
                            <b-col>
                                <p-multiselect v-show="associateWithTerm"
                                               v-model="filters.termSelections"
                                               :options="termOptions"
                                               placeholder="Filter by selected terms"></p-multiselect>
                            </b-col>
                        </b-row>
                        <b-row class="pt-3 ml-2" v-if="dccFilter">
                            <b-col cols="12" md="auto" lg="3" xl="2">
                                <p-checkbox v-model="filters.isDccFilter"
                                            label="Filter by DCC"></p-checkbox>
                            </b-col>
                            <b-col cols="12" lg="9" xl="5" v-show="filters.isDccFilter">
                                <DCCsSelector class="container-fluid"
                                              @selection-change="
                                        filters.dccSelections = $event
                                    " />
                            </b-col>
                        </b-row>
                        <p-search-table :include-search-input='false'
                                        v-bind="$attrs"
                                        :empty-text="emptyText"
                                        :submit-search-as-post="submitSearchAsPost"
                                        title="Items"
                                        :api-destination="apiDestination"
                                        :search-destination="computedSearchDestination"
                                        :filters="computedFilters"
                                        :fields="searchResultFields"
                                        :sort-by="defaultSort"
                                        add-new-button
                                        :single-select="singleSelect"
                                        :single-select-button-label="singleSelectButtonLabel"
                                        :disable-row-check="disableRowCheck"
                                        :show-per-page-selector="true"
                                        :show-top-paging="false"
                                        :enable-row-selection="enableRowSelection"
                                        :enable-dual-selection="enableDualSelection"
                                        :per-page="perPage"
                                        @submit-selection="submitSelections"
                                        @per-page-change="perPageUpdate"
                                        v-model="selections">
                            <!-- pass throught the p-search-table above-table slot -->
                            <slot name="above-table" slot="above-table" />
                            <template v-slot:cell(authorTitleOrDescriptionWithVariants)="{ value, item, index }">
                                <div>{{ item.authorTitleOrDescription }}</div>
                                <div>{{ item.bookVariant?bookVariantFormatter(item.bookVariant):(item.gmVariant1?item.gmVariant1+' - '+item.gmVariant2:'') }}</div>
                            </template>
                        </p-search-table>
                    </div>
                </template>
            </template>
        </p-modal>
    </div>
</template>

<script>
import { currencyFormatter } from './Common/Formatters';
import uniqueid from 'lodash.uniqueid';
import StoreListManager from '@/components/mixins/StoreListManager.js';
import { searchPlaceHolderFormatter,bookVariantFormatter } from './Common/Formatters';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import DCCsSelector from '@/components/DCCsSelector.vue';
export default {
    name: 'ProductQuickSearch',
    inheritAttrs: false,
    mixins: [StoreListManager],
    props: {
        previousSelections: {
            type: Array,
            default: () => []
        },
        items: {
            type: Array,
            default: () => null
        },
        label: {
            type: String,
            default: 'Add Products'
        },
        title: {
            type: String,
            default: null
        },
        iconPlus:{
            type:Boolean,
            default:false
        },
        showContent:{
            type:Boolean,
            default:true
        },
        searchDestination: {
            type: String,
            default: 'search'
        },
        enableRowSelection: {
            type: Boolean,
            default: true
        },
        enableDualSelection: {
            type: Boolean,
            default: false
        },
        disableRowCheck: {
            type: Function,
            default: () => false
        },
        booksOnly: {
            type: Boolean,
            default: false
        },
        okOnly: {
            type: Boolean,
            default: false
        },
        okTitle: {
            type: String,
            default: "OK"
        },
        includeTerms: {
            type: Boolean,
            default: false
        },
        excludeUsed: {
            type: Boolean,
            default: false
        },
        locationSpecificOnly:{
            type: Boolean,
            default: false
        },
        supplierIds: {
            type: Array,
            default: () => []
        },
        courseRequestFilters: {
            type:Boolean,
            default:false
        },
        dccFilter: {
            type:Boolean,
            default:false
        },
        excludedSkus: {
            type: Array,
            default: () => []
        },
        excludedSkuTerms: {
            type: Array,
            default: () => []
        },
        termIds: {
            type: Array,
            default: () => []
        },
        disableAddButton: {
            type: Boolean,
            default: false
        },
        showWholesaleOption: {
            type: Boolean,
            default: false
        },
        //true: include only active items
        //false: only inactive
        //null: all items
        isActiveFilter: {
            type: Boolean,
            default: null
        },
        isWantListTitle: {
            type: Boolean,
            default: null
        },
        name: {
            //name must be unique on the page, if it is not passed
            //in a unique name will be genearted for you.
            type: String,
            required: false,
            default() {
                return uniqueid('ProductQuickSearch_');
            }
        },
        emptyText: String,
        //can either pass in the fieldSet definition as an array,
        //or provide the name of a predefined field that can be reused multiple places
        fieldSet: {
            type: [String, Array],
            default: 'defaultFields'
        },
        singleSelect: {
            type: Boolean,
            default: false
        },
        hideButtons: {
            type: Boolean,
            default: false
        },
        singleSelectButtonLabel: {
            type: String,
            default: 'Add'
        },
        defaultSort: {
            type: String,
            default: 'authorTitleOrDescription'
        },
        includeProducts: {
            type: Boolean,
            default: false
        },
        prefillSearch: {
            type: String,
            default: null
        },
        showBuyersGuide: {
            type: Boolean,
            default: false
        },
        startWithTermChecked: {
            type: Boolean,
            default: false
        },
        buttonLabel: {
            type: String,
            default: null
        },
        adoptedOnly: {
            type: Boolean,
            default: false
        },
        noDigital: {
            type: Boolean,
            default: false
        },
        useBuyersGuideCost: {
            type: Boolean,
            default: false
        },
        associateAvailableTerms: {
            type: Boolean,
            default: false
        },
        //allow addition of specific use filters without
        //having to complicate this component
        additionalFilters: {
            type: Object,
            default: null
        },
        addFilterOptions: {
            type: Array,
            default: () => []
        },
        removeFilterOptions: {
            type: Array,
            default: () => []
        },
        apiDestination: {
            type: String,
            default: "productquicksearch"
        },
        perPage:{
            type: Number,
            default: 10
        },
        isBusy: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            associateWithTerm: this.startWithTermChecked,
            withWholesaleValue: false,
            includeBuyersGuideTitles: false,
            selectListOptions: {
                terms: [],
                courseDepartments: [],
                courses: []
            },
            selections: [],
            filters: {
                automatic: "",
                locationId: null,
                searchBy:'All',                
                isDccFilter: false,
                selectedTerms: [],
                selectedCourseDepartments: [],
                selectedCourses: [],
                courseDepartmentTermIds: [],
                courseDepartmentIds: [],
                courseIds: [],
                termSelections: [],
                dccSelections: [],
                options:[
                    'All',
                    'SKU',
                    'Author',
                    'Title',
                    'Description',
                    'Catalog #',
                    'ISBN',
                    'Barcode',
                    'Supplier',
                    'Book Key'
                ]           
                
            },
            defaultFields: [
                {
                    key: 'sku',
                    label: 'SKU',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    key: 'authorTitleOrDescription',
                    label: 'Author/Title or Description',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    key: 'isbn',
                    label: 'Catalog # / ISBN',
                    sortable: true,
                    automaticSearch: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'supplier',
                    sortable: true
                },
                 {
                    key: 'supplierId',
                      hidden: true
                },
                {
                    key: 'termName',
                    label: 'Term',
                    sortable: true,
                    hidden: true
                },
                {
                    key: 'barcodes',
                    label: 'Barcodes',
                    hidden: true,
                    automaticSearch: true
                },
                {
                    key: 'bookVariant',
                    label: 'Type',
                    sortable: true,
                    sortByFormatted: true,
                    formatter: bookVariantFormatter
                },
                {
                    key: 'copyright',
                    sortable: true,
                    label: 'Variant 1 /Copyright'
                },
                {
                    key: 'edition',
                    sortable: true,
                    label: 'Variant 2 /Edition'
                }
            ],
            wantListFields: [
                {
                    key: 'sku',
                    label: 'SKU',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    key: 'authorTitleOrDescription',
                    label: 'Author/Title or Description',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    key: 'isbn',
                    label: 'Catalog # / ISBN',
                    sortable: true,
                    automaticSearch: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'termName',
                    label: 'Term',
                    sortable: true
                },
                {
                    key: 'openToBuy'
                },
                {
                    key: 'barcodes',
                    label: 'Barcodes',
                    hidden: true,
                    automaticSearch: true
                },
                {
                    key: 'bookVariant',
                    label: 'Type',
                    formatter: bookVariantFormatter
                },
                {
                    key: 'retail',
                    sortable: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'cost',
                    sortable: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                }
            ],
            invoiceFields: [
                {
                    key: 'sku',
                    label: 'SKU',
                    automaticSearch: true,
                    sortable: true
                },
                {
                    label: 'Author',
                    key: 'author',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Description',
                    key: 'authorTitleOrDescription',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },

                {
                    key: 'authorTitleOrDescription',
                    label: 'Author/Title or Description',
                    sortable: true
                },
                {
                    key: 'isbnCatalog',
                    label: 'Catalog # / ISBN',
                    sortable: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'catalogNumber',
                    label: 'Catalog #',
                    automaticSearch: true,
                    hidden:true
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    automaticSearch: true,
                    hidden:true
                },
                {
                    key: 'termName',
                    label: 'Term',
                    sortable: true
                },
                {
                    key: 'barcodes',
                    label: 'Barcode',
                    hidden: true,
                    automaticSearch: true
                },
                {
                    key: 'bookVariant',
                    label: 'Type',
                    formatter: bookVariantFormatter
                },
                {
                    key: 'retail',
                    sortable: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'cost',
                    sortable: true,
                    formatter: currencyFormatter,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'supplier',
                    label: 'Supplier',
                    hidden: false,
                    automaticSearch: true
                }
            ],
            courseRequestFields: [
                {
                    key: 'authorTitleOrDescription',
                    formatter: (value, key, item) => {
                        return value ? value : item.author + '/' + item.title;
                    },
                    sortByFormatted: true,
                    sortable: true
                },
                {
                    key: 'isbn',
                    label: 'Catalog # / ISBN',
                    sortable: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'supplier',
                    sortable: true
                },
                {
                    key: 'sku',
                    label: 'SKU',
                    sortable: true
                },
                {
                    key: 'bookVariant',
                    label: 'Type',
                    formatter: bookVariantFormatter,
                    sortByFormatted: true,
                    sortable: true
                },
                {
                    key: 'copyright',
                    label: 'Color / Copyright'
                },
                {
                    key: 'edition',
                    label: 'Size / Edition'
                },
                {
                    key: 'stockOnHand',
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    label: 'Inv'
                }
            ],
            poFields: [
                {
                    key: 'authorTitleOrDescription',
                    label: 'Author/Title or Description',
                    automaticSearch: true,
                    sortable: true
                },
                 {
                    key: 'defaultBarcode',
                    label: 'Default Barcode',
                    automaticSearch: true,
                    sortable: true
                },
                {
                     key: 'sku',
                    label: 'SKU',
                    sortable: true,
                    hidden :true,
                    automaticSearch: false,
                },
                {
                    key: 'supplier',
                    sortable: true
                },

                 {
                    key: 'isbn',
                    label: 'Catalog # / ISBN',
                    automaticSearch: true,
                    sortable: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'bookVariant',
                    label: 'Type',
                    formatter: bookVariantFormatter
                },
                {
                    key: 'termName',
                    label: 'Term',
                    sortable: true
                },
                {
                    key: 'copyright',
                    label: 'Variant 1 /Copyright',
                    tdClass: 'text-center',
                },
                {
                    key: 'edition',
                    label: 'Variant 2 /Edition',
                    tdClass: 'text-center',
                },
                 {
                    key: 'stockOnHand',
                    label: 'SOH',
                    sortable: true
                },
                {
                    key: 'onOrder',
                    label: 'On Order',
                    sortable: true
                },                
                
            ],
            buyersGuideFields: [
                {
                    key: 'author',
                    label: 'Author',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'title',
                    label: 'Title',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'imprint',
                    label: 'Imprint',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'edition',
                    label: 'Edition',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'copyright',
                    label: 'Copyright',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    sortable: true,
                    automaticSearch: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'retail',
                    label: 'Price',
                    sortable: true,
                    formatter: currencyFormatter,
                },
                  {
                    key: 'binding',
                    label: 'Binding',
                      sortable: true,
                      tdClass: 'noWrap'

                }

            ],
            memoDetailFieldsWholesale: [
                {
                    key: 'sku',
                    label: 'Sku',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'termName',
                    label: 'Term',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'authorTitleOrDescription',
                    sortable: true,
                },
                {
                    label: 'Author',
                    key: 'author',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Title',
                    key: 'title',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    label: 'Description',
                    key: 'authorTitleOrDescription',
                    sortable: true,
                    automaticSearch: true,
                    hidden:true
                },
                {
                    key: 'isbn',
                    label: 'ISBN',
                    sortable: true,
                    automaticSearch: true,
                    tdClass: 'noWrap'
                }
            ],
            linkedTitleFields: [
                {
                    key: 'bookKey',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'buyersGuideAuthor',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'buyersGuideTitle',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'buyersGuideIsbn',
                    label: 'Buyers Guide ISBN',
                    tdClass: 'noWrap',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'productAuthor',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'productTitle',
                    sortable: true,
                    automaticSearch: true
                },
                {
                    key: 'productIsbn',
                    label: 'Product ISBN',
                    tdClass: 'noWrap',
                    sortable: true,
                    automaticSearch: true
                }
            ],
            webOrderFields: [
                {
                    key: 'authorTitleOrDescription',
                    formatter: (value, key, item) => {
                        return value ? value : item.author + '/' + item.title;
                    },
                    label: 'Author/Title or Description',
                    sortByFormatted: true,
                    sortable: true
                },
                {
                    key: 'defaultBarcode',
                    label: 'Default Barcode',
                    sortable: true
                },
                {
                    key: 'sku',
                    label: 'SKU',
                    sortable: true
                },
                {
                    key: 'isbn',
                    label: 'Catalog # /ISBN',
                    automaticSearch: true,
                    sortable: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'bookVariant',
                    label: 'Type',
                    formatter: bookVariantFormatter
                },
                {
                    key: 'termName',
                    label: 'Term',
                    sortable: true
                },
                {
                    key: 'copyright',
                    label: 'Variant 1 /Copyright',
                    tdClass: 'text-center',
                },
                {
                    key: 'edition',
                    label: 'Variant 2 /Edition',
                    tdClass: 'text-center',
                },
                {
                    key: 'stockOnHand',
                    label: 'SOH',
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'text-right'
                },
                {
                    key: 'retail',
                    label: 'Selling Price',
                    sortable: true,
                    thClass: 'text-center',
                    tdClass: 'text-right',
                    formatter: currencyFormatter,
                },
                
            ],
            productTransferFields: [
                {
                    key: 'authorTitleOrDescriptionWithVariants',
                    label: 'Author/Title or Description'
                },
                {
                    key: 'isbnCatalog',
                    label: 'Catalog # / ISBN',
                    sortable: true,
                    tdClass: 'noWrap'
                },
                {
                    key: 'defaultBarcode',
                    label: 'Default Barcode',
                    automaticSearch: true,
                    sortable: true,
                }
            ]
        };
    },
    computed: {
        computedSearchDestination() {
            let destination = this.searchDestination;
            if (this.includeTerms && this.associateWithTerm) {
                destination = 'termassociatedsearch';
            }
            if (this.showBuyersGuide && this.includeBuyersGuideTitles) {
                destination = 'buyersguidesearch';
            }
            return destination;
        },
        submitSearchAsPost() {
            let postDestinations = ['termassociatedsearch', 'purchaseordersearch','search']
            return postDestinations.includes(this.computedSearchDestination);
        },
        termOptions() {
            return [
                ...this.selectListOptions.terms.filter(x => x.filter === 'True')
            ];
        },
        computedFilters() {
            return {
                ...this.additionalFilters,
                ...this.filters,
                booksOnly: this.booksOnly,
                isWantListTitle: this.isWantListTitle,
                isActive: this.isActiveFilter,
                supplierIds: this.supplierIds,
                excludedSkus: this.excludedSkus,
                adoptedOnly: this.adoptedOnly,
                noDigital: this.noDigital,
                withWholesaleValue: this.withWholesaleValue,
                useBuyersGuideCost: this.useBuyersGuideCost,
                associateAvailableTerms: this.associateAvailableTerms,
                excludedSkuTerms: this.excludedSkuTerms.map(x => ({
                        ExcludedSkuTerm: x
                    })),
                    terms: this.termIds.length
                        ? this.termIds.map(x => ({ TermId: x }))
                        : this.filters.termSelections.map(x => ({
                            TermId: x.value
                        })),
                    excludeUsed: this.excludeUsed,
                    locationSpecificOnly: this.locationSpecificOnly,
                    includeProducts: this.includeProducts,
                    termIds: this.termIds.length
                        ? this.termIds
                        : this.filters.termSelections.map(x => x.value),
                    dccIds: this.filters.dccSelections.map(x => x.dccId)
                };
            },
            searchResultFields() {
                if (Array.isArray(this.fieldSet)) {
                    return this.fieldSet
                }

                switch (this.fieldSet) {
                    case 'defaultFields':
                        return this.defaultFields;
                    case 'courseRequestFields':
                        return this.courseRequestFields;
                    case 'wantListFields':
                        return this.wantListFields;
                    case 'poFields':
                        return this.poFields;
                    case 'buyersGuideFields':
                        return this.buyersGuideFields;
                    case 'invoiceFields':
                        return this.invoiceFields;
                    case 'memoDetailFieldsWholesale':
                        return this.memoDetailFieldsWholesale;
                    case 'linkedTitleFields':
                        return this.linkedTitleFields;
                    case 'webOrderFields':
                        return this.webOrderFields;
                    case 'productTransferFields':
                        return this.productTransferFields;
                }
                return null;
            },
            searchMessageComputed() {
                return searchPlaceHolderFormatter(this.filterOptionsComputed, this.filters.searchBy);
            },
            filterOptionsComputed() {
                let options = this.filters.options;

                if (this.addFilterOptions.length > 0) {
                    options = options.concat(this.addFilterOptions);
                }

                function optionFilter(item, removeFilterOptions) {
                    return removeFilterOptions.some(x => x === item);
                }

                if (this.removeFilterOptions.length > 0) {
                    options = options.filter(x => !optionFilter(x, this.removeFilterOptions));
                }

                return options;
            },
            filterDepartmentOptionsComputed() {
                let courseOptions = this.selectListOptions.courses;

                function filterDepartments(dept, terms) {
                    return courseOptions.filter(x => x.filter == dept.value).some(y => y.sections.some(z => terms.some(t => z.termId == t.value)));
                }

                if (this.filters.selectedTerms.length > 0) {
                    let filteredDepartmentOptions = this.selectListOptions.courseDepartments.filter(x => filterDepartments(x, this.filters.selectedTerms));
                    return filteredDepartmentOptions.map(c => ({
                        ...c,
                        text: c.text + ' - ' + c.campusAbbreviation
                    }));
                }
                else {
                    return this.selectListOptions.courseDepartments.map(c => ({
                        ...c,
                        text: c.text + ' - ' + c.campusAbbreviation
                    }));
                }
            },
            filterCourseOptionsComputed() {
                let courseOptions = this.selectListOptions.courses;

                function filterByDepartment(course, filteredTerms, filteredDepartments) {
                    return filteredDepartments.some(x => x.value == course.filter && course.sections.some(y => filteredTerms.length == 0 || filteredTerms.some(z => z.value == y.termId)));
                }

                if (this.filters.selectedCourseDepartments.length > 0) {
                    let filteredTerms = this.filters.selectedTerms.filter(y => y.courseDepartmentIds.some(z => this.filters.selectedCourseDepartments.some(a => a.value == z)));
                    let filteredCourseOptions = courseOptions.filter(x => filterByDepartment(x, filteredTerms, this.filters.selectedCourseDepartments));
                    return filteredCourseOptions.map(c => ({
                        ...c,
                        text: c.text + ' - ' + c.departmentName
                    }));
                }
                else {
                    return courseOptions.map(c => ({
                        ...c,
                        text: c.text + ' - ' + c.departmentName
                    }));
                }
            }
        },
        watch:
        {
            associateWithTerm: function () {
                if (this.associateWithTerm) {
                    this.defaultFields.find(x => x.key === 'termName').hidden = false;
                } else {
                    this.defaultFields.find(x => x.key === 'termName').hidden = true;
                }
            }
        },
        methods: {
            bookVariantFormatter: bookVariantFormatter,
            async initialize() {
                this.filters.courseDepartmentTermIds = [];
                this.filters.courseDepartmentIds = [];
                this.filters.courseIds = [];
                this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                this.filters.termSelections = this.termIds.map(x => this.$store.getters.getTermById(x))
                this.selections = [...this.previousSelections];
                this.prefillSearch
                    ? (this.filters.automatic = this.prefillSearch)
                    : (this.filters.automatic = '');
            },
            focusSearchInput() {
                this.$refs.searchInput?.focus();
            },
            perPageUpdate(value) {
                this.$emit('per-page-change', value)
            },
            submitSelections() {
                let emitValue = this.selections;

                if (this.singleSelect) {
                    emitValue = this.selections[0];
                }

                this.$emit('selections', emitValue);
                this.$bvModal.hide(this.name);
            }
        },
        components: {
            DCCsSelector
        }
    };
</script>

<style scoped lang="scss">
    /deep/ .noWrap {
        white-space: nowrap;
    }

    /deep/ .card {
        box-shadow: none;
    }

    /deep/ .card-body {
        margin-bottom: -3.5rem;
        padding-bottom: 0rem;
    }
</style>
