
import { max, min_value } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('unique-course-name', {
    params: ['isDuplicateCourseName', 'rowData'],
    message(field) {
        return `${field} cannot be repeated within this campus/department.`
    },
    validate(value, { isDuplicateCourseName, rowData }) {
        return !isDuplicateCourseName(value, rowData);
    }
});

extend('unique-campus-abbreviation', {
    params: ['isDuplicateCampusAbbreviation', 'rowData'],
    message(field) {
        return `${field} is used for a different campus.`
    },
    validate(value, { isDuplicateCampusAbbreviation, rowData }) {
        return !isDuplicateCampusAbbreviation(value, rowData);
    }
});

extend('unique-department-code', {
    params: ['isDuplicateDepartmentCode', 'rowData'],
    message(field) {
        return `${field} is used for a different department in the same campus.`
    },
    validate(value, { isDuplicateDepartmentCode, rowData }) {
        return !isDuplicateDepartmentCode(value, rowData);
    }
});

export default {
    data() {
        return {
            courseConfiguration: {
                type: 'courses',
                label: 'Courses with Campuses and Departments',
                sampleNotes: [
                    '"The imported course will be matched with an existing department and campus if it they can be found with the same name."',
                    '"If a department/campus is not found by the provided name, a new department/campus will be created with the provided details."',
                    '"Existing departments/campuses will not be updated by the provided details."',
                ],
                importNotes: () => {return this.courseImportNotes},
                fields: [
                    {
                        //course is unique within the department.
                        key: 'CourseName',
                        canConvertCase: true,
                        hasErrorCalculator: (value, row, checkOriginalData)  => {
                            return (
                                value === '' ||
                                !max.validate(value, { length: 80 })
                                || this.isDuplicateCourseName(value, row, checkOriginalData)
                            );
                        },

                        rules: {
                            'unique-course-name': {
                                isDuplicateCourseName: this
                                    .isDuplicateCourseName
                            },
                            required: true,
                            max: { length: 80 }
                        },
                        addRowDataToRule: 'unique-course-name',
                        samples: [
                            '"303"',
                            '"304"',
                            '"430"'
                        ]
                    },
                    {
                        key: 'CourseDescription',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            return (
                                value === '' ||
                                !max.validate(value, { length: 255 })
                            );
                        },
                        rules: 'required|max:255',
                        samples: [
                            '"Computers in Arts & Science"',
                            '"Computers in Arts & Science"',
                            '"Computers in Arts & Science"'
                        ]
                    },
                    {
                        key: 'CourseComments',
                        canConvertCase: true,
                        hasErrorCalculator: value => {
                            return (
                                !max.validate(value, { length: 255 })
                            );
                        },
                        rules: 'max:255',
                        samples: [
                            '"Comment on course 303"',
                            '"Comment on course 304"',
                            '"Comment on course 430"'
                        ]
                    },

                    //department name is unique in campus
                    {
                        key: 'DepartmentName',
                        hasErrorCalculator: value => {
                            return (
                                value === '' ||
                                !max.validate(value, { length: 80 })
                            );
                        },
                        rules: 'required|max:80',
                        samples: [
                            '"Computer Science"',
                            '"Computer Science"',
                            '"Computer Science"'
                        ]
                    },
                    {
                        key: 'DepartmentDescription',
                        hasErrorCalculator: value => {
                            return (
                                value === '' ||
                                !max.validate(value, { length: 255 })                            );
                        },
                        rules: 'required|max:255',
                        samples: [
                            '"Description for Computer Science"',
                            '"Description for Computer Science"',
                            '"Description for Computer Science"'
                        ]
                    },

                    {
                        key: 'DepartmentIsActive',
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        dataType: 'yesNoOptions',
                        warningDefault: true,
                        rules: 'min_value:0',
                        samples: [
                            '"Yes"',
                            '"Yes"',
                            '"Yes"'
                        ]
                    },

                    //Campus name is unique
                    {
                        key: 'CampusName',
                        hasErrorCalculator: value => {
                            return (
                                this.isEmpty(value) ||
                                !max.validate(value, { length: 80 })
                            );
                        },
                        rules: 'required|max:80',
                        samples: [
                            '"Greenville University"',
                            '"Greenville University"',
                            '"Greenville University"'
                        ]
                    },
                    {
                        key: 'CampusDescription',
                        hasErrorCalculator: value => {
                            return (
                                this.isEmpty(value) || !max.validate(value, { length: 255 })
                            );
                        },
                        rules: 'required|max:255',
                        samples: [
                            '"Description for Greenville University"',
                            '"Description for Greenville University"',
                            '"Description for Greenville University"'
                        ]
                    },

                    // abbreviation is unique //unique-campus-abbreviation
                    {
                        key: 'CampusAbbreviation',
                        canConvertCase: true,
                        hasErrorCalculator: (value, row, checkOriginalData)  => {
                            return (
                                value === '' ||
                                !max.validate(value, { length: 40 })
                                || this.isDuplicateCampusAbbreviation(value, row, checkOriginalData)
                            );
                        },
                        rules: {
                            'unique-campus-abbreviation': {
                                isDuplicateCampusAbbreviation: this
                                    .isDuplicateCampusAbbreviation
                            },
                            required: true,
                            max: { length: 40 }
                        },
                        addRowDataToRule: 'unique-campus-abbreviation',
                        samples: [
                            '"GRE"',
                            '"GRE"',
                            '"GRE"'
                        ]
                    },
                    {
                        key: 'CampusIsActive',
                        hasWarningCalculator: value => {
                            return (
                                value != '' &&
                                value != undefined &&
                                !min_value.validate(value, { min: 0 })
                            );
                        },
                        dataType: 'yesNoOptions',
                        warningDefault: true,
                        rules: 'min_value:0',
                        samples: [
                            '"Yes"',
                            '"Yes"',
                            '"Yes"'
                        ]
                    },
                ]
            }
        };
    },

    computed:{
        courseImportNotes(){
            let notes = []

            if(this.newDepartments.length > 0){
                let departments = this.newDepartments.map(x => x.DepartmentName + '/' + x.CampusName).filter((v, i, a) => a.indexOf(v) === i)
                notes.push(`The following ${departments.length} new department(s) will be created: ${departments.join(', ')}`  )
            }

            if(this.newCampuses.length > 0){
                let campuses = this.newCampuses.map(x => x.CampusName).filter((v, i, a) => a.indexOf(v) === i)
                notes.push(`The following ${campuses.length} new campus(es) will be created: ${campuses.join(', ')}`)
            }
            return notes;
        },

        newDepartments(){
            return this.loadedData.filter(x =>
                !this.selectListOptions.courseDepartments
                    .some(s => s.campusName?.toLowerCase() === x.CampusName?.toLowerCase()
                    && s.text?.toLowerCase() === x.DepartmentName?.toLowerCase()))
        },
        newCampuses(){
            return this.loadedData.filter(x =>
                !this.selectListOptions.campuses.some(s => s.text === x.CampusName))
        }
    },
    methods: {
        isDuplicateCourseName(courseName, rowData, checkOriginalData) {
            return this.isExistingCourseName(courseName, rowData, checkOriginalData)
                    || this.isDuplicatedInImportDataCourseName(courseName, rowData, checkOriginalData);

        },

        isExistingCourseName(courseName, rowData){
            return courseName != ''
                && this.selectListOptions
                    .courses
                    .some(x => x.text?.toLowerCase() === courseName?.toLowerCase()
                            && x.departmentName?.toLowerCase() === rowData.DepartmentName?.toLowerCase()
                            && rowData.CampusName?.toLowerCase() === x.campusName?.toLowerCase())
        },

        isDuplicatedInImportDataCourseName(courseName, rowData, checkOriginalData){
            return courseName != ''
                && this.loadedData
                    .filter(x => (
                                    x.CourseName?.toLowerCase() === courseName?.toLowerCase()
                                    && x.DepartmentName?.toLowerCase() === rowData.DepartmentName?.toLowerCase()
                                    && rowData.CampusName?.toLowerCase() === x.CampusName?.toLowerCase()
                                )
                            || (
                                    checkOriginalData
                                    && (
                                            x.originalData?.CourseName.toLowerCase() === courseName?.toLowerCase()
                                            && x.originalData?.DepartmentName.toLowerCase() === rowData.DepartmentName?.toLowerCase()
                                            && x.originalData?.CampusName.toLowerCase() === rowData.CampusName?.toLowerCase()
                                        )
                                    )
                                )
                    .length > 1
        },


        isDuplicateCampusAbbreviation(campusAbbreviation, rowData, checkOriginalData){
            return this.isExistingCampusAbbreviation(campusAbbreviation, rowData)
                    || this.isDuplicatedInImportDataCampusAbbreviation(campusAbbreviation, rowData, checkOriginalData);
        },

        isExistingCampusAbbreviation(campusAbbreviation, rowData){
            return campusAbbreviation != ''
                && this.selectListOptions
                    .campuses
                    .some(x => x.abbreviation?.toLowerCase() === campusAbbreviation?.toLowerCase() && x.text.toLowerCase() != rowData.CampusName.toLowerCase())
        },

        isDuplicatedInImportDataCampusAbbreviation(campusAbbreviation, rowData, checkOriginalData){
             //campus Abbreviation should be unique, so if we find a campus with a different name, but a same Abbreviation there is an issue
            return campusAbbreviation != ''
                && this.loadedData
                    .some(x => (
                                    x.CampusAbbreviation.toLowerCase() === campusAbbreviation?.toLowerCase()
                                    && x.CampusName.toLowerCase() != rowData.CampusName.toLowerCase()
                                )
                            ||
                                (
                                    checkOriginalData
                                    &&  (
                                            x.originalData?.CampusAbbreviation === campusAbbreviation
                                            && x.originalData?.CampusName.toLowerCase() != rowData.CampusName.toLowerCase()
                                        )
                                    )
                                )
        },


        isDuplicateDepartmentCode(departmentCode, rowData, checkOriginalData){
            return this.isExistingDepartmentCode(departmentCode, rowData, checkOriginalData)
                    || this.isDuplicatedInImportDataDepartmentCode(departmentCode, rowData, checkOriginalData);
        },

        isExistingDepartmentCode(departmentCode, rowData){
            //same code reused in different dept on the same campus
            return departmentCode != ''
                && this.selectListOptions
                    .courseDepartments
                    .some(x => x.code === departmentCode  && x.campusName.toLowerCase() === rowData.CampusName.toLowerCase()
                        && !x.text.toLowerCase() === rowData.DepartmentName.toLowerCase() )
        },

        isDuplicatedInImportDataDepartmentCode(departmentCode, rowData, checkOriginalData){
            return departmentCode != ''
                && this.loadedData
                    .some(x => (
                                    x.DepartmentCode === departmentCode
                                    && x.CampusName?.toLowerCase() === rowData.CampusName?.toLowerCase()
                                    && x.DepartmentName?.toLowerCase() != rowData.DepartmentName?.toLowerCase()
                                )
                            || (
                                    checkOriginalData
                                    &&
                                        (
                                            x.originalData?.DepartmentCode === departmentCode
                                            && x.originalData?.CampusName?.toLowerCase() === rowData.CampusName?.toLowerCase()
                                            && x.originalData?.DepartmentName?.toLowerCase() != rowData.DepartmentName?.toLowerCase())
                                        )
                                    )
        },
    },
};
